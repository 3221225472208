import { Box, Grid, Text } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';
import Heading from '@components/Heading';
import Container from '@components/LayoutStructure/Container';
import {
  CallToActionProps,
  DEFAULT_CTA_BEHAVIOR,
  getIcon,
} from '@components/NewCallToAction/NewCallToAction';
import { getButtonColor, getColor } from '@utils/getColor';
import dynamic from 'next/dynamic';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { useModalDispatch } from '~/contexts/ModalContext';
import { MagnoliaImage } from '~/types/Magnolia';
const DAMImage = dynamic(() => import('@components/Image/DAMImage'));

export interface BannerCallOutProps {
  title: string;
  titleColor?: string;
  titleColorRange?: string;
  description: string;
  descriptionColor?: string;
  descriptionColorRange?: string;
  image?: MagnoliaImage;
  backgroundColor: string;
  backgroundColorRange: string;
  cta?: CallToActionProps;
}

const BannerCallOutComponent = (props: BannerCallOutProps): JSX.Element => {
  const { showModal } = useModalDispatch();
  const {
    title,
    titleColor,
    titleColorRange,
    description,
    descriptionColor,
    descriptionColorRange,
    image,
    backgroundColor = 'secondary',
    backgroundColorRange = '200',
    cta,
  } = props;

  const bgColor =
    backgroundColor === 'white' || backgroundColor === 'black'
      ? backgroundColor
      : `${backgroundColor}.${backgroundColorRange}`;

  const openModal = (inquiryId) => {
    showModal(inquiryId);
  };

  return (
    <Container position="relative" px="0">
      <Grid
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent="center"
        textAlign={{ base: 'center', md: 'left' }}
        alignItems="center"
        display="flex"
        backgroundColor={bgColor}
        borderBottomRadius={{ base: 0, xl: 12 }}
        mt={{ base: 8, md: 0 }}
        p={{ base: 8, md: 0 }}
        gap={{ base: 4, md: 6 }}
      >
        {image && (
          <Box
            borderRadius={{ base: '6', md: 0 }}
            overflow="hidden"
            width="100%"
            borderBottomLeftRadius={{ xl: 12 }}
            height={{ base: '7rem', md: '11rem' }}
            maxW={{ base: '11rem', md: '15rem' }}
            position="relative"
          >
            <DAMImage
              src={image}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </Box>
        )}
        <Box display="flex" flexDirection="column">
          <Heading
            title={title}
            size="md"
            withContainer={false}
            textAlign={{ base: 'center', md: 'left' }}
            pb={2}
            color={getColor(titleColor, titleColorRange)}
          />
          <Text
            fontSize="md"
            color={getColor(descriptionColor, descriptionColorRange)}
          >
            {description}
          </Text>
        </Box>
        {cta && (
          <Box mr={{ base: 0, md: 6 }}>
            {cta?.isInquiry ? (
              <Button
                width={{ base: '100%', sm: 'auto' }}
                colorScheme={cta?.bgColor}
                variant={cta?.state}
                onClick={() => openModal(cta?.inquiryId)}
                {...getButtonColor(cta)}
                {...getIcon(cta?.icon)}
              >
                {cta?.text}
              </Button>
            ) : (
              cta?.url && (
                <a
                  href={`${cta?.type ? `${cta.type}:` : ''}${cta?.url}`}
                  key={cta?.text}
                  target={cta?.behavior || DEFAULT_CTA_BEHAVIOR}
                  rel={cta?.rel?.join(' ') || ''}
                >
                  <Button
                    width={{ base: '100%', sm: 'auto' }}
                    colorScheme={cta?.bgColor}
                    variant={cta?.state}
                    {...getButtonColor(cta)}
                    {...getIcon(cta?.icon)}
                  >
                    {cta?.text}
                  </Button>
                </a>
              )
            )}
          </Box>
        )}
      </Grid>
    </Container>
  );
};

const BannerCallOut: React.FC<BannerCallOutProps> = withHydrationOnDemand({
  on: ['idle', 'visible'],
  initialVisible: true,
})(BannerCallOutComponent);

export default BannerCallOut;
